/*
 *
 * Helper: `redirectToDefaultHomePage`.
 *
 */
import Sentry from '@dbh/sentry';

/**
 * Redirects the browser to the default homepage URL, when there is no country
 * nor locale found in the current URL. This "redirect" should be done using
 * `react-router` and on the server, but it may not be possible with version
 * three (@example no regular expressions for the routes definitions) and our setup.
 * Otherwise, it may be done using an "Express router/middleware" on the server.
 * The query parameters must be forwarded, because they are important for
 * tracking in `GA4`.
 */
const redirectToDefaultHomePage = () => {
  const missingLocaleError = Error(
    `(\`app\`) The country and locale are missing in the URL: \`${location.href}\`.`,
  );
  // eslint-disable-next-line no-console
  console.error(missingLocaleError);

  Sentry.captureException(missingLocaleError);

  const homePageDefaultWithQuery = `/IT/it-IT${location.search}`;
  location.assign(homePageDefaultWithQuery);
};

export default redirectToDefaultHomePage;
